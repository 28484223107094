// WARNING, do not touch the double quote (") chars used in version, commitHash, and installationDate, it used by the CI/CD
export const envBase = {
  "czamApiUrl": "/czam-api",
  "restrictionsApiUrl": "/casino-restrictions-api",
  "casinoInjApiUrl": "/casino-inj-api",
  "swiftSaleApiUrl": "/swift-sale-api",
  "appsApiUrl": "/user-apps-api",
  "ocmConnectApiUrl": "/ocm-connect-api",
  "customerExtendedApiUrl": "/api-fiche-client",
  "casinoLoyaltyApiUrl": "/casino-loyalty-api",
  "walletApiUrl": "/wallet-api",
  "notificationCenterApiUrl": "/notifications-api",
  "hotelUnifiedDataApiUrl": "/hotel-unifieddata-api",
  "datascienceApiUrl": "/datascience-api",
  "version": "1.19.1.3550",
  "commitHash": "6e13c1ac52d370164c996b028322877fa4384189",
  "packageGenerationDate": "2024-12-12T14:56:06Z",
  "asyncClientEntry": false,
  "numberOfSearchChar": 4,
  daysBeforeSignalCardExpireSoon: 15
};
